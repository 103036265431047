import React from 'react';
import PropTypes from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { ProHomepage } from './ProHomepage';

export const ProHomepageContainer = ({ slug, thdSEOBotDetection }) => {
  return (
    <ErrorBoundary name="my-homepage-content">
      <QueryProvider cacheKey="pro-homepage">
        <ProHomepage slug={slug} thdSEOBotDetection={thdSEOBotDetection} />
      </QueryProvider>
    </ErrorBoundary>
  );
};

ProHomepageContainer.propTypes = {
  slug: PropTypes.string,
  thdSEOBotDetection: PropTypes.bool
};

ProHomepageContainer.defaultProps = {
  slug: undefined,
  thdSEOBotDetection: false
};
